import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = ({ image, className, style, objectFit = "cover" }) => {
  if (!image?.asset) {
    return null;
  }

  return (
    <>
      {(image?.asset?.url?.includes(`.svg`) && (
        <img
          src={image.asset.url}
          alt={image.altText}
          className={className}
          style={{ ...style, objectFit }}
        />
      )) || (
        <GatsbyImage
          image={image.asset.gatsbyImageData}
          alt={image.altText}
          className={className}
          objectFit={objectFit}
          style={style}
          // style={{ width: `100%` }}
        />
      )}
    </>
  );
};

export default Image;
