import React, { useRef, useState } from "react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";

const Carousel = ({
  effect,
  goIndex,
  id,
  loop,
  options,
  nav,
  slides,
  className,
  style
}) => {
  SwiperCore.use([Autoplay, EffectFade]);

  const swiper = useRef(null);

  const [current, setCurrent] = useState(1);

  const go = index => {
    if (swiper?.current?.swiper?.slideNext) {
      swiper.current.swiper.slideTo(index);
    }
  };

  const next = () => {
    if (swiper?.current?.swiper?.slideNext) {
      swiper.current.swiper.slideNext();
    }
  };

  const prev = () => {
    if (swiper?.current?.swiper?.slidePrev) {
      swiper.current.swiper.slidePrev();
    }
  };

  // useEffect(() => {
  //   if (goIndex === null || !swiper?.current?.swiper?.slideTo) {
  //     return;
  //   }

  //   swiper.current.swiper.slideTo(goIndex + 1);
  // }, [goIndex]);

  return (
    <>
      <Swiper
        style={style}
        id={id}
        // ref={swiper}
        effect={effect}
        loop={loop}
        onSlideChange={({ realIndex }) => {
          setCurrent(realIndex);
        }}
        {...options}
      >
        {slides.map((slide, slideIndex) => {
          const slideKey = `${slide.key}-${slideIndex}`;
          return <SwiperSlide key={slideKey}>{slide}</SwiperSlide>;
        })}
      </Swiper>

      {nav &&
        nav({
          current,
          go,
          next,
          prev
        })}
    </>
  );
};

export default Carousel;
