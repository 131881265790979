import React from "react";
import PortableText from "./PortableText";

const EndNotes = ({ notes }) => {
  return (
    <section id={`end-notes`} className="grid-main mb-20">
      <h2 className="mb-8 col-span-full lg:col-start-3 lg:col-end-11 font-house text-h2 text-brown">
        End Notes
      </h2>
      <ul className="col-span-full lg:col-start-2 lg:col-end-12">
        {notes.map(note => (
          <EndNote key={note._key} note={note} />
        ))}
      </ul>
    </section>
  );
};

export default EndNotes;

const EndNote = ({ note: { number, _rawText } }) => {
  return (
    <li id={`end-note-${number}`} className="endnote w-full mb-10">
      <a href={`/#endnote-ref-${number}`}>
        <h3 className="mb-2 font-times text-b1 text-brown">{number}</h3>
        <PortableText blocks={_rawText} className="" />
      </a>
    </li>
  );
};
