import React from "react";

import Image from "../Image";

const CoolieImage = ({ image, figNum }) => {
  return (
    <figure className="col-span-full lg:col-start-3 lg:col-end-11 mt-6 mb-6">
      <Image image={image} />
      <a id={`img-ref-${figNum}`} href={`/#image-reference-${figNum}`}>
        <p className="font-times text-b1 text-brown">fig {figNum}</p>
      </a>
    </figure>
  );
};

export default CoolieImage;
