import React from "react";

const TitleSection = ({ authors, subTitle, title }) => {
  return (
    <section className="w-full h-screen bg-gold bg-cane-pattern">
      <article className="grid-main h-full py-8 md:py-16">
        <div className="col-span-full md:col-start-3 md:col-end-11 lg:col-start-5 lg:col-end-9 flex flex-col items-center justify-evenly px-4 py-4 md:px-8 md:py-8 bg-blue">
          <header>
            {title && (
              <h1 className="font-house text-d1 text-brown text-center">
                {title}
              </h1>
            )}

            {subTitle && (
              <h2 className="font-house text-h3 text-brown text-center">
                The Sugar Empire of the South Pacific
              </h2>
            )}
          </header>

          {authors && (
            <h3 className="font-house text-h3 text-brown">{authors}</h3>
          )}
        </div>
      </article>
    </section>
  );
};

export default TitleSection;
