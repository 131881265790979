import React, { useState, useEffect, useContext } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

import { AppContext } from "../context/AppContext";

import Image from "./Image";

import menuIcon from "../../static/menu.svg";

const Nav = () => {
  const {
    sanityCoolieCutCanePage: { bodyContent },
    sanitySiteSettings: { siteLogo }
  } = useStaticQuery(graphql`
    query Nav {
      sanityCoolieCutCanePage {
        bodyContent {
          ... on SanityChapter {
            _key
            _type
            referenceNumber
            content {
              ... on SanityHeader {
                _key
                _type
                title
              }
            }
          }
        }
      }
      sanitySiteSettings {
        siteLogo {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  `);

  // const [menu, setMenu] = useState(false);
  const { menu, setMenu } = useContext(AppContext);

  const chapters = bodyContent.filter(content => content._type === "chapter");

  return (
    <header className="fixed w-full z-10">
      <div className="bg-white bg-opacity-80">
        <div
          className="h-24 flex items-center justify-between mx-auto px-8 lg:py-8 lg:px-40"
          style={{ maxWidth: `1366px` }}
        >
          <a href="https://badfijigyals.com" target="_blank">
            <Image image={siteLogo} style={{ width: `85px`, height: `45px` }} />
          </a>
          <button
            id="menu-toggle"
            aria-expanded={menu}
            aria-controls="menu"
            onClick={() => setMenu(!menu)}
          >
            <span className="absolute text-transparent pointer-events-none">
              Menu
            </span>
            <img
              src={menuIcon}
              alt="menu icon"
              width="100%"
              height="100%"
              aria-hidden
              className="w-8"
            />
          </button>
        </div>
      </div>

      {menu && (
        <nav
          role="navigation"
          className="block absolute top-24 left-0 right-0 bottom-0 h-screen overflow-y-scroll"
        >
          <div className="bg-white bg-opacity-80">
            <div
              className="menu-overlay mx-auto pt-16 pb-4 px-4 lg:pt-24 lg:pb-40 lg:px-40"
              style={{ maxWidth: `1366px` }}
            >
              <ul id="menu" className="">
                <li className="mb-12">
                  <a
                    href="/#fullPageImage"
                    onClick={() => setMenu(false)}
                    className="inline-block"
                  >
                    <h3 className="font-house text-h2">Cover Page</h3>
                  </a>
                </li>
                <li className="mb-12">
                  <a
                    href="/#imageSlider"
                    onClick={() => setMenu(false)}
                    className="inline-block"
                  >
                    <h3 className="font-house text-h2">Carousel</h3>
                  </a>
                </li>

                {chapters.map(({ referenceNumber, content }) => {
                  const header = content
                    .filter(cont => cont._type === "header")
                    .pop();

                  console.log(header);
                  return (
                    <li className="mb-12">
                      <a
                        href={`/#chapter-${referenceNumber}`}
                        onClick={() => setMenu(false)}
                        className="inline-block"
                      >
                        <p className="font-house text-b1">
                          Chapter {referenceNumber}
                        </p>
                        <h3 className="font-house text-h2">{header.title}</h3>
                      </a>
                    </li>
                  );
                })}

                <li className="mb-12">
                  <a
                    href="/#end-notes"
                    onClick={() => setMenu(false)}
                    className="inline-block"
                  >
                    <h3 className="font-house text-h2">End Notes</h3>
                  </a>
                </li>
                <li className="mb-12">
                  <a
                    href="/#bibliography"
                    onClick={() => setMenu(false)}
                    className="inline-block"
                  >
                    <h3 className="font-house text-h2">Bibliography</h3>
                  </a>
                </li>
                <li>
                  <a
                    href="/#image-reference"
                    onClick={() => setMenu(false)}
                    className="inline-block"
                  >
                    <h3 className="font-house text-h2">Image References</h3>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Nav;
