import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import Chapter from "./sections/Chapter";
import Image from "./Image";
import TitleSection from "./sections/TitleSection";
import Carousel from "./Carousel";

const BodyContent = ({ sections }) => {
  const bodyJSX = [];

  const [numberOfSlides, setNumberOfSlides] = useState(3);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    if (isTabletOrMobile) {
      setNumberOfSlides(1.1);
    } else {
      setNumberOfSlides(3);
    }
  }, [isTabletOrMobile]);

  sections.forEach(section => {
    const {
      _key,
      content,
      referenceNumber,
      authors,
      subTitle,
      title,
      image,
      images
    } = section;

    let sectionJSX = <></>;

    switch (section._type) {
      case "chapter":
        sectionJSX = (
          <section key={section._key} id={`${section._type}`}>
            <Chapter key={_key} content={content} id={referenceNumber} />
          </section>
        );
        break;

      case "fullPageImage":
        sectionJSX = (
          <section
            key={section._key}
            id={`${section._type}`}
            style={{ backgroundColor: `#601c16` }}
          >
            <div className="grid-main h-full">
              <figure className="col-span-full">
                <Image image={image} className="h-screen" objectFit="contain" />
              </figure>
            </div>
          </section>
        );
        break;

      case "titleSection":
        sectionJSX = (
          <section key={section._key} id={`${section._type}`}>
            <TitleSection
              key={_key}
              authors={authors}
              subTitle={subTitle}
              title={title}
            />
          </section>
        );
        break;

      case "imageSlider":
        sectionJSX = (
          <section
            key={section._key}
            id={`${section._type}`}
            className="my-10 py-20 bg-white"
          >
            <div className="relative overflow-hidden h-full">
              <Carousel
                style={{ height: `100%`, width: `100%`, gridColumn: `1/-1` }}
                id={`${section._type}`}
                loop={true}
                options={{
                  autoplay: {
                    delay: 3000,
                    reverseDirection: false
                  },
                  autoHeight: false,
                  centeredSlides: true,
                  direction: `horizontal`,
                  slidesPerView: `${numberOfSlides}`,
                  spaceBetween: 10,
                  initialSlide: 0
                }}
                slides={images.map((img, imgIndex) => {
                  return (
                    <Image
                      key={img._key}
                      image={img}
                      alt="test"
                      className="h-full"
                      objectFit="contain"
                    />
                  );
                })}
              />
            </div>
          </section>
        );
        break;

      default:
        break;
    }

    bodyJSX.push(sectionJSX);
  });

  return bodyJSX;
};

export default BodyContent;
