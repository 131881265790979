import React from "react";

import Header from "./Header";
import Quote from "./Quote";
import Paragraph from "./Paragraph";
import CoolieImage from "./CoolieImage";

const Chapter = ({ content, id }) => {
  const chapterJSX = [];

  content.forEach(cont => {
    let contentJSX = <></>;

    const {
      _key,
      title,
      subTitle,
      _rawBody,
      bottomDecorator,
      topDecorator,
      image,
      figNum
    } = cont;

    switch (cont._type) {
      case "header":
        contentJSX = <Header key={_key} primary={title} secondary={subTitle} />;
        break;

      case "quote":
        contentJSX = (
          <Quote
            key={_key}
            body={_rawBody}
            top={topDecorator}
            bottom={bottomDecorator}
          />
        );
        break;

      case "paragraph":
        contentJSX = <Paragraph key={_key} body={_rawBody} />;
        break;

      case "coolieImage":
        contentJSX = <CoolieImage key={_key} image={image} figNum={figNum} />;
        break;

      default:
        break;
    }

    chapterJSX.push(contentJSX);
  });

  return (
    <article
      id={`chapter-${id}`}
      className="chapter grid-main h-full py-8 md:py-16"
    >
      {chapterJSX}
    </article>
  );
};

export default Chapter;
