import React from "react";

const Header = ({ primary, secondary }) => {
  return (
    <header className="col-span-full lg:col-start-3 lg:col-end-11 mb-8">
      {primary && <h2 className="font-house text-h2 text-brown">{primary}</h2>}
      {secondary && (
        <h3 className="font-house text-h3 text-brown">{secondary}</h3>
      )}
    </header>
  );
};

export default Header;
