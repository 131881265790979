import React, { useContext } from "react";

import SEO from "./SEO";
import Nav from "./Nav";

const Layout = ({ title, url, page, children }) => {
  return (
    <>
      <SEO title={title} url={url} />
      <Nav />
      <div className={`page ${page && page}`}>
        <main id="content" className="content overflow-hidden">
          {children}
        </main>
      </div>
    </>
  );
};

export default Layout;
