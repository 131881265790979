import React from "react";
import PortableText from "./PortableText";

const Bibliography = ({ bibliography }) => {
  return (
    <section id={`bibliography`} className="grid-main mb-20">
      <h2 className="mb-8 col-span-full lg:col-start-3 lg:col-end-11 font-house text-h2 text-brown">
        Bibliography
      </h2>
      <ul className="col-span-full lg:col-start-2 lg:col-end-12">
        {bibliography.map(note => (
          <EndNote key={note._key} note={note} />
        ))}
      </ul>
    </section>
  );
};

export default Bibliography;

const EndNote = ({ note: { _rawBody } }) => {
  return (
    <li className="bibliography w-full mb-10">
      <PortableText blocks={_rawBody} className="" />
    </li>
  );
};
