import React from "react";
import PortableText from "./PortableText";

const ImageRefs = ({ images }) => {
  return (
    <section id={`image-reference`} className="grid-main mb-20">
      <h2 className="mb-8 col-span-full lg:col-start-3 lg:col-end-11 font-house text-h2 text-brown">
        Image References
      </h2>
      <ul className="col-span-full lg:col-start-2 lg:col-end-12">
        {images.map(img => (
          <ImgRef key={img._key} img={img} />
        ))}
      </ul>
    </section>
  );
};

export default ImageRefs;

const ImgRef = ({ img: { number, _rawText } }) => {
  return (
    <li id={`image-reference-${number}`} className="image-ref w-full mb-10">
      <a href={`/#img-ref-${number}`}>
        <h3 className="mb-2 font-times text-b1 text-brown">Figure {number}</h3>
        <PortableText blocks={_rawText} className="" />
      </a>
    </li>
  );
};
