import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import Note from "./sections/Note";

// const sanityConfig = { projectId: "tj5lg93h", dataset: "production" };

export default function PortableText({ blocks, className, type }) {
  const serializers = {
    types: {
      block: props => {
        if (props.children[0] === "") {
          return <br />;
        }

        return (
          <p className={`${className} font-times text-b1 text-brown`}>
            {props.children}
          </p>
        );
      }
    },
    marks: {
      strong: ({ children }) => (
        <strong className="font-times">{children}</strong>
      ),
      em: ({ children }) => <em className="font-times">{children}</em>,
      link: ({ children, mark }) => (
        <a
          href={mark.href}
          target="_blank"
          rel="noopener noreferrer"
          className="font-times"
        >
          {children}
        </a>
      ),
      note: ({ children, mark }) => (
        <Note sup={children} url={mark.referenceNumber} />
      )
    }
  };

  if (blocks) {
    return <BlockContent blocks={blocks} serializers={serializers} />;
  } else {
    return null;
  }
}
