import React from "react";

import PortableText from "../PortableText";
import Image from "../Image";

const Quote = ({ body, top, bottom }) => {
  return (
    <aside className="col-span-full md:col-start-2 md:col-end-12 lg:col-start-3 lg:col-end-11 my-10">
      {top && <Image image={top} className="" />}

      <blockquote className="font-times text-b1 text-brown text-center">
        <PortableText blocks={body} />
      </blockquote>

      {bottom && <Image image={bottom} className="" />}
    </aside>
  );
};

export default Quote;
