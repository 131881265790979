import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import BodyContent from "../components/BodyContent";
import EndNotes from "../components/EndNotes";
import Bibliography from "../components/Bibliography";
import ImageRefs from "../components/ImageRefs";

export const PageQuery = graphql`
  query CoolieCutCane {
    sanityCoolieCutCanePage {
      bibliography {
        _key
        _rawBody
      }
      bodyContent {
        ... on SanityChapter {
          _key
          _type
          referenceNumber
          content {
            ... on SanityCoolieImage {
              _key
              _type
              figNum
              image {
                asset {
                  altText
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            ... on SanityHeader {
              _key
              _type
              subTitle
              title
            }
            ... on SanityParagraph {
              _key
              _type
              _rawBody
            }
            ... on SanityQuote {
              _key
              _type
              _rawBody
              bottomDecorator {
                altText
                asset {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              topDecorator {
                altText
                asset {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        ... on SanityFullPageImage {
          _key
          _type
          image {
            altText
            asset {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        ... on SanityTitleSection {
          _key
          _type
          authors
          subTitle
          title
        }

        ... on SanityImageSlider {
          _key
          _type
          images {
            _key
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                aspectRatio: 1.33
                fit: CROP
              )
            }
            altText
          }
        }
      }
      endNotes {
        _key
        _rawText
        number
      }
      imageReferences {
        _key
        _rawText
        number
      }
      title
    }
  }
`;

const Index = ({
  data: {
    sanityCoolieCutCanePage: {
      bibliography,
      bodyContent,
      endNotes,
      imageReferences,
      title
    }
  }
}) => {
  return (
    <Layout title={title}>
      <BodyContent sections={bodyContent} />
      <EndNotes notes={endNotes} />
      <Bibliography bibliography={bibliography} />
      <ImageRefs images={imageReferences} />
    </Layout>
  );
};

export default Index;
