import React from "react";

import PortableText from "../PortableText";

const Paragraph = ({ body }) => {
  return (
    <PortableText
      blocks={body}
      className="col-span-full lg:col-start-1 lg:col-end-13 mb-6"
    />
  );
};

export default Paragraph;
