import React from "react";

const Note = ({ sup, url }) => {
  return (
    <a
      id={`endnote-ref-${url}`}
      href={`/#end-note-${url}`}
      className="cursor-pointer"
    >
      <sup>{sup}</sup>
    </a>
  );
};

export default Note;
